import React from "react"
import { Helmet } from "react-helmet"
import "../../static/assets/scss/pages/page.scss"
import LayoutDark from "./shared/layout/layout-dark"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
import { navigate } from "gatsby-link"

const routes = require("../types/routes")

export default class WhitePaper extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: 2021 Compliance Checklist (Success)</title>
          <meta name="robots" content="noindex, nofollow, noarchive" />
          <meta
            name="title"
            content="Contingent: 2021 Compliance Checklist (Success)"
          />
          <link
            rel="canonical"
            href={`${process.env.BASE_URL}/2021-compliance-checklist/success/`}
          />
          <meta
            name="description"
            content="Our mission is to be the go-to enterprise risk platform for every major company in the world, boldly reshaping the enterprise risk and supply chain data insight ecosystem. "
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/2021-compliance-checklist/success/`}
          />
          <meta
            property="og:title"
            content="Contingent: 2021 Compliance Checklist (Success)"
          />
          <meta
            property="og:description"
            content="Our mission is to be the go-to enterprise risk platform for every major company in the world, boldly reshaping the enterprise risk and supply chain data insight ecosystem. "
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/whitepaper-image.png`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/2021-compliance-checklist/success/`}
          />
          <meta
            property="twitter:title"
            content="Contingent: 2021 Compliance Checklist (Success)"
          />
          <meta
            property="twitter:description"
            content="Our mission is to be the go-to enterprise risk platform for every major company in the world, boldly reshaping the enterprise risk and supply chain data insight ecosystem. "
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/whitepaper-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic white-paper"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column centered"}>
                  <div className={"content"}>
                    <p className={"tag"}>Success</p>
                    <h1 className={"title"}>We've received your application</h1>
                    <p className={"description"}>
                      Thanks for choosing to download our 2021 compliance
                      checklist for procurement professionals. You will soon
                      receive an email containing the PDF document of this
                      checklist.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
